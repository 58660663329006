* {
    margin: 0;
    padding: 0;
}

@font-face {
    font-family: 'TTHovesPro';
    src: url('/public/fonts/tt-hoves-pro/medium.ttf') format('truetype');
    font-weight: 500;
    font-style: 'normal';
}

:root {
    --sail-color-black-rgb: 0, 0, 0;
    --sail-color-white-rgb: 255, 255, 255;
    --sail-color-gray-10-rgb: 250, 251, 251;
    --sail-color-gray-20-rgb: 245, 246, 247;
    --sail-color-gray-30-rgb: 235, 237, 240;
    --sail-color-gray-40-rgb: 223, 226, 230;
    --sail-color-gray-50-rgb: 194, 199, 208;
    --sail-color-gray-60-rgb: 179, 185, 196;
    --sail-color-gray-70-rgb: 166, 174, 187;
    --sail-color-gray-80-rgb: 152, 161, 176;
    --sail-color-gray-90-rgb: 137, 147, 164;
    --sail-color-gray-100-rgb: 122, 134, 153;
    --sail-color-gray-200-rgb: 107, 120, 142;
    --sail-color-gray-300-rgb: 93, 107, 130;
    --sail-color-gray-400-rgb: 80, 95, 121;
    --sail-color-gray-500-rgb: 66, 82, 109;
    --sail-color-gray-600-rgb: 53, 71, 100;
    --sail-color-gray-700-rgb: 36, 55, 87;
    --sail-color-gray-800-rgb: 21, 41, 75;
    --sail-color-gray-900-rgb: 9, 30, 66;
    --sail-color-blue-10-rgb: 230, 240, 254;
    --sail-color-blue-50-rgb: 232, 240, 254;
    --sail-color-blue-100-rgb: 185, 209, 250;
    --sail-color-blue-200-rgb: 151, 186, 248;
    --sail-color-blue-300-rgb: 104, 155, 245;
    --sail-color-blue-400-rgb: 74, 135, 243;
    --sail-color-blue-500-rgb: 29, 105, 240;
    --sail-color-blue-600-rgb: 26, 96, 218;
    --sail-color-blue-700-rgb: 21, 75, 170;
    --sail-color-blue-800-rgb: 16, 58, 132;
    --sail-color-blue-900-rgb: 12, 44, 101;
    --sail-color-pink-50-rgb: 255, 233, 241;
    --sail-color-pink-100-rgb: 255, 187, 210;
    --sail-color-pink-200-rgb: 255, 154, 189;
    --sail-color-pink-300-rgb: 255, 108, 159;
    --sail-color-pink-400-rgb: 255, 79, 140;
    --sail-color-pink-500-rgb: 255, 35, 111;
    --sail-color-pink-600-rgb: 232, 32, 101;
    --sail-color-pink-700-rgb: 181, 25, 79;
    --sail-color-pink-800-rgb: 140, 19, 61;
    --sail-color-pink-900-rgb: 107, 15, 47;
    --sail-color-yellow-50-rgb: 255, 248, 230;
    --sail-color-yellow-100-rgb: 255, 234, 176;
    --sail-color-yellow-200-rgb: 255, 224, 138;
    --sail-color-yellow-300-rgb: 255, 210, 84;
    --sail-color-yellow-400-rgb: 255, 201, 51;
    --sail-color-yellow-500-rgb: 255, 188, 0;
    --sail-color-yellow-600-rgb: 232, 171, 0;
    --sail-color-yellow-700-rgb: 181, 133, 0;
    --sail-color-yellow-800-rgb: 140, 103, 0;
    --sail-color-yellow-900-rgb: 107, 79, 0;
    --sail-color-green-50-rgb: 230, 253, 237;
    --sail-color-green-100-rgb: 176, 249, 199;
    --sail-color-green-200-rgb: 138, 247, 172;
    --sail-color-green-300-rgb: 84, 243, 134;
    --sail-color-green-400-rgb: 51, 241, 111;
    --sail-color-green-500-rgb: 0, 237, 75;
    --sail-color-green-600-rgb: 0, 216, 68;
    --sail-color-green-700-rgb: 0, 168, 53;
    --sail-color-green-800-rgb: 0, 130, 41;
    --sail-color-green-900-rgb: 0, 100, 32;
    --sail-color-red-50-rgb: 255, 230, 230;
    --sail-color-red-100-rgb: 255, 176, 176;
    --sail-color-red-200-rgb: 255, 138, 138;
    --sail-color-red-300-rgb: 255, 84, 84;
    --sail-color-red-400-rgb: 255, 51, 51;
    --sail-color-red-500-rgb: 255, 0, 0;
    --sail-color-red-600-rgb: 232, 0, 0;
    --sail-color-red-700-rgb: 181, 0, 0;
    --sail-color-red-800-rgb: 140, 0, 0;
    --sail-color-red-900-rgb: 107, 0, 0;
    --sail-color-black: rgb(var(--sail-color-black-rgb));
    --sail-color-white: rgb(var(--sail-color-white-rgb));
    --sail-color-gray-10: rgb(var(--sail-color-gray-10-rgb));
    --sail-color-gray-20: rgb(var(--sail-color-gray-20-rgb));
    --sail-color-gray-30: rgb(var(--sail-color-gray-30-rgb));
    --sail-color-gray-40: rgb(var(--sail-color-gray-40-rgb));
    --sail-color-gray-50: rgb(var(--sail-color-gray-50-rgb));
    --sail-color-gray-60: rgb(var(--sail-color-gray-60-rgb));
    --sail-color-gray-70: rgb(var(--sail-color-gray-70-rgb));
    --sail-color-gray-80: rgb(var(--sail-color-gray-80-rgb));
    --sail-color-gray-90: rgb(var(--sail-color-gray-90-rgb));
    --sail-color-gray-100: rgb(var(--sail-color-gray-100-rgb));
    --sail-color-gray-200: rgb(var(--sail-color-gray-200-rgb));
    --sail-color-gray-300: rgb(var(--sail-color-gray-300-rgb));
    --sail-color-gray-400: rgb(var(--sail-color-gray-400-rgb));
    --sail-color-gray-500: rgb(var(--sail-color-gray-500-rgb));
    --sail-color-gray-600: rgb(var(--sail-color-gray-600-rgb));
    --sail-color-gray-700: rgb(var(--sail-color-gray-700-rgb));
    --sail-color-gray-800: rgb(var(--sail-color-gray-800-rgb));
    --sail-color-gray-900: rgb(var(--sail-color-gray-900-rgb));
    --sail-color-blue-50: rgb(var(--sail-color-blue-50-rgb));
    --sail-color-blue-100: rgb(var(--sail-color-blue-100-rgb));
    --sail-color-blue-200: rgb(var(--sail-color-blue-200-rgb));
    --sail-color-blue-300: rgb(var(--sail-color-blue-300-rgb));
    --sail-color-blue-400: rgb(var(--sail-color-blue-400-rgb));
    --sail-color-blue-500: rgb(var(--sail-color-blue-500-rgb));
    --sail-color-blue-600: rgb(var(--sail-color-blue-600-rgb));
    --sail-color-blue-700: rgb(var(--sail-color-blue-700-rgb));
    --sail-color-blue-800: rgb(var(--sail-color-blue-800-rgb));
    --sail-color-blue-900: rgb(var(--sail-color-blue-900-rgb));
    --sail-color-pink-50: rgb(var(--sail-color-pink-50-rgb));
    --sail-color-pink-100: rgb(var(--sail-color-pink-100-rgb));
    --sail-color-pink-200: rgb(var(--sail-color-pink-200-rgb));
    --sail-color-pink-300: rgb(var(--sail-color-pink-300-rgb));
    --sail-color-pink-400: rgb(var(--sail-color-pink-400-rgb));
    --sail-color-pink-500: rgb(var(--sail-color-pink-500-rgb));
    --sail-color-pink-600: rgb(var(--sail-color-pink-600-rgb));
    --sail-color-pink-700: rgb(var(--sail-color-pink-700-rgb));
    --sail-color-pink-800: rgb(var(--sail-color-pink-800-rgb));
    --sail-color-pink-900: rgb(var(--sail-color-pink-900-rgb));
    --sail-color-yellow-50: rgb(var(--sail-color-yellow-50-rgb));
    --sail-color-yellow-100: rgb(var(--sail-color-yellow-100-rgb));
    --sail-color-yellow-200: rgb(var(--sail-color-yellow-200-rgb));
    --sail-color-yellow-300: rgb(var(--sail-color-yellow-300-rgb));
    --sail-color-yellow-400: rgb(var(--sail-color-yellow-400-rgb));
    --sail-color-yellow-500: rgb(var(--sail-color-yellow-500-rgb));
    --sail-color-yellow-600: rgb(var(--sail-color-yellow-600-rgb));
    --sail-color-yellow-700: rgb(var(--sail-color-yellow-700-rgb));
    --sail-color-yellow-800: rgb(var(--sail-color-yellow-800-rgb));
    --sail-color-yellow-900: rgb(var(--sail-color-yellow-900-rgb));
    --sail-color-green-50: rgb(var(--sail-color-green-50-rgb));
    --sail-color-green-100: rgb(var(--sail-color-green-100-rgb));
    --sail-color-green-200: rgb(var(--sail-color-green-200-rgb));
    --sail-color-green-300: rgb(var(--sail-color-green-300-rgb));
    --sail-color-green-400: rgb(var(--sail-color-green-400-rgb));
    --sail-color-green-500: rgb(var(--sail-color-green-500-rgb));
    --sail-color-green-600: rgb(var(--sail-color-green-600-rgb));
    --sail-color-green-700: rgb(var(--sail-color-green-700-rgb));
    --sail-color-green-800: rgb(var(--sail-color-green-800-rgb));
    --sail-color-green-900: rgb(var(--sail-color-green-900-rgb));
    --sail-color-red-50: rgb(var(--sail-color-red-50-rgb));
    --sail-color-red-100: rgb(var(--sail-color-red-100-rgb));
    --sail-color-red-200: rgb(var(--sail-color-red-200-rgb));
    --sail-color-red-300: rgb(var(--sail-color-red-300-rgb));
    --sail-color-red-400: rgb(var(--sail-color-red-400-rgb));
    --sail-color-red-500: rgb(var(--sail-color-red-500-rgb));
    --sail-color-red-600: rgb(var(--sail-color-red-600-rgb));
    --sail-color-red-700: rgb(var(--sail-color-red-700-rgb));
    --sail-color-red-800: rgb(var(--sail-color-red-800-rgb));
    --sail-color-red-900: rgb(var(--sail-color-red-900-rgb));
    --black: var(--sail-color-black);
    --white: var(--sail-color-white);
    --gray10: var(--sail-color-gray-10);
    --gray20: var(--sail-color-gray-20);
    --gray30: var(--sail-color-gray-30);
    --gray40: var(--sail-color-gray-40);
    --gray50: var(--sail-color-gray-50);
    --gray60: var(--sail-color-gray-60);
    --gray70: var(--sail-color-gray-70);
    --gray80: var(--sail-color-gray-80);
    --gray90: var(--sail-color-gray-90);
    --gray100: var(--sail-color-gray-100);
    --gray200: var(--sail-color-gray-200);
    --gray300: var(--sail-color-gray-300);
    --gray400: var(--sail-color-gray-400);
    --gray500: var(--sail-color-gray-500);
    --gray600: var(--sail-color-gray-600);
    --gray700: var(--sail-color-gray-700);
    --gray800: var(--sail-color-gray-800);
    --gray900: var(--sail-color-gray-900);
    --blue50: var(--sail-color-blue-50);
    --blue100: var(--sail-color-blue-100);
    --blue200: var(--sail-color-blue-200);
    --blue300: var(--sail-color-blue-300);
    --blue400: var(--sail-color-blue-400);
    --blue500: var(--sail-color-blue-500);
    --blue600: var(--sail-color-blue-600);
    --blue700: var(--sail-color-blue-700);
    --blue800: var(--sail-color-blue-800);
    --blue900: var(--sail-color-blue-900);
    --pink50: var(--sail-color-pink-50);
    --pink100: var(--sail-color-pink-100);
    --pink200: var(--sail-color-pink-200);
    --pink300: var(--sail-color-pink-300);
    --pink400: var(--sail-color-pink-400);
    --pink500: var(--sail-color-pink-500);
    --pink600: var(--sail-color-pink-600);
    --pink700: var(--sail-color-pink-700);
    --pink800: var(--sail-color-pink-800);
    --pink900: var(--sail-color-pink-900);
    --yellow50: var(--sail-color-yellow-50);
    --yellow100: var(--sail-color-yellow-100);
    --yellow200: var(--sail-color-yellow-200);
    --yellow300: var(--sail-color-yellow-300);
    --yellow400: var(--sail-color-yellow-400);
    --yellow500: var(--sail-color-yellow-500);
    --yellow600: var(--sail-color-yellow-600);
    --yellow700: var(--sail-color-yellow-700);
    --yellow800: var(--sail-color-yellow-800);
    --yellow900: var(--sail-color-yellow-900);
    --green50: var(--sail-color-green-50);
    --green100: var(--sail-color-green-100);
    --green200: var(--sail-color-green-200);
    --green300: var(--sail-color-green-300);
    --green400: var(--sail-color-green-400);
    --green500: var(--sail-color-green-500);
    --green600: var(--sail-color-green-600);
    --green700: var(--sail-color-green-700);
    --green800: var(--sail-color-green-800);
    --green900: var(--sail-color-green-900);
    --red50: var(--sail-color-red-50);
    --red100: var(--sail-color-red-100);
    --red200: var(--sail-color-red-200);
    --red300: var(--sail-color-red-300);
    --red400: var(--sail-color-red-400);
    --red500: var(--sail-color-red-500);
    --red600: var(--sail-color-red-600);
    --red700: var(--sail-color-red-700);
    --red800: var(--sail-color-red-800);
    --red900: var(--sail-color-red-900);
    --sail-font-size-10: 10px;
    --sail-font-size-12: 12px;
    --sail-font-size-14: 14px;
    --sail-font-size-16: 16px;
    --sail-font-size-18: 18px;
    --sail-font-size-20: 20px;
    --sail-font-size-24: 24px;
    --sail-font-size-30: 30px;
    --sail-font-size-36: 36px;
    --sail-font-size-48: 48px;
    --sail-font-size-60: 60px;
    --sail-font-size-72: 72px;
    --sail-font-lineHeight-16: 16px;
    --sail-font-lineHeight-20: 20px;
    --sail-font-lineHeight-24: 24px;
    --sail-font-lineHeight-32: 32px;
    --sail-font-lineHeight-36: 36px;
    --sail-font-lineHeight-48: 48px;
    --sail-font-lineHeight-64: 64px;
    --sail-font-lineHeight-72: 72px;

    --font-size--small: var(--sail-font-size-12);
    --font-size--medium: var(--sail-font-size-16);
    --font-size--large: var(--sail-font-size-18);

    --font-weight--light: 300;
    --font-weight--regular: 400;
    --font-weight--medium: 500;
    --font-weight--semi-bold: 600;
    --font-weight--bold: 700;

    --sail-font-weight-regular: 400;
    --sail-font-weight-medium: 500;
    --sail-font-weight-bold: 700;
    --sail-font-weight-link: var(--sail-font-weight-medium);

    --sail-color-text-blue: var(--sail-color-blue-900);
    --sail-color-text-link: var(--sail-color-text-blue);
    --sail-color-text-hover: var(--sail-color-blue-500);
    --sail-color-text-link-hover: var(--sail-color-text-hover);

    --sail-color-text: var(--gray800);

    --sail-opacity-8: 0.08;
    --sail-opacity-12: 0.12;
    --sail-opacity-shadow-key: var(--sail-opacity-12);
    --sail-opacity-shadow-ambient: var(--sail-opacity-8);
    --sail-color-shadow-key: rgba(
        var(--sail-color-black-rgb),
        var(--sail-opacity-shadow-key)
    );
    --sail-color-shadow-ambient: rgba(
        var(--sail-color-gray-700-rgb),
        var(--sail-opacity-shadow-ambient)
    );
    --sail-shadow-small: 0 2px 5px 0 var(--sail-color-shadow-ambient),
        0 1px 1px 0 var(--sail-color-shadow-key);
    --sail-shadow-medium: 0 7px 14px 0 var(--sail-color-shadow-ambient),
        0 3px 6px 0 var(--sail-color-shadow-key);
    --sail-shadow-large: 0 15px 35px 0 var(--sail-color-shadow-ambient),
        0 5px 15px 0 var(--sail-color-shadow-key);
    --sail-shadow-extraLarge: 0 50px 100px 0 var(--sail-color-shadow-ambient),
        0 15px 35px 0 var(--sail-color-shadow-ambient),
        0 5px 15px 0 var(--sail-color-shadow-key);

    --sail-radius: 6px;
    --sail-card-radius: var(--sail-radius);
    --border-radius: var(--sail-radius);
    --border-radius--small: 3px;
    --border-radius--rounded: 50%;
    --border-radius--input: 3px;

    --padding: 20px;
    --padding--xx-small: 5px;
    --padding--x-small: 10px;
    --padding--small: 15px;
    --padding--large: 25px;
    --padding--x-large: 30px;
    --padding--xx-large: 50px;

    --margin: 20px;
    --margin--xx-small: 5px;
    --margin--x-small: 10px;
    --margin--small: 15px;
    --margin--large: 25px;
    --margin--x-large: 30px;
    --margin--xx-large: 50px;

    --border-gray50: var(--gray50) 1px solid;

    --sail-font-system: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
        'Helvetica Neue', 'Ubuntu';

    --sail-font-family: var(--sail-font-system), sans-serif;
}

.text-success {
    color: var(--green500);
}

.bg-white {
    background-color: var(--white) !important;
}

body,
html {
    color: var(--sail-color-text);
    font-size: 16px;
    font-weight: 500;
    font-family: var(--sail-font-family);
    line-height: var(--sail-font-lineHeight-24);
    font-family: 'Inter', sans-serif;
}
.inter {
    font-family: 'Inter', sans-serif;
}
.tt-hoves-pro {
    font-family: 'TTHovesPro', sans-serif;
}
.jakarta-sans {
    font-family: 'Plus Jakarta Sans', sans-serif;
}
.margin-auto {
    margin: auto !important;
}

h1 {
    font-size: var(--sail-font-size-60);
    line-height: var(--sail-font-lineHeight-64);
    margin: var(--margin) var(--margin);
}

h2 {
    font-size: var(--sail-font-size-48);
    line-height: var(--sail-font-lineHeight-48);
    margin: var(--margin) var(--margin);
}

h3 {
    font-size: var(--sail-font-size-36);
    line-height: var(--sail-font-lineHeight-36);
    margin: var(--margin) var(--margin);
}

h4 {
    font-size: var(--sail-font-size-30);
    line-height: var(--sail-font-lineHeight-32);
    margin: var(--margin) var(--margin);
}

h5 {
    font-size: var(--sail-font-size-24);
    line-height: var(--sail-font-lineHeight-24);
    margin: var(--margin) var(--margin);
}

h6 {
    font-size: var(--sail-font-size-20);
    line-height: var(--sail-font-lineHeight-26);
    margin: var(--margin) var(--margin);
}

p {
    font-size: var(--sail-font-size-16);
    margin: var(--margin--x-small) var(--margin);
}

.font-weight-light {
    font-weight: var(--font-weight--light);
}
.font-weight-bold {
    font-weight: var(--font-weight--bold);
}

.font-18 {
    font-size: var(--sail-font-size-18);
}

.p-small {
    font-size: var(--sail-font-size-12);
    line-height: var(--sail-font-lineHeight-16);
}

.border-gray800 {
    border: var(--border-gray50);
}
.black-text {
    color: #000;
}
.light-gray-text {
    color: var(--gray500);
}
.light-gray-text-200 {
    color: var(--gray200);
}

.shadow-small {
    box-shadow: var(--sail-shadow-small);
}

.shadow-medium {
    box-shadow: var(--sail-shadow-medium);
}

.shadow-large {
    box-shadow: var(--sail-shadow-large);
}

.shadow-extraLarge {
    box-shadow: var(--sail-shadow-extraLarge);
}

.font-weight-regular {
    font-weight: var(--font-weight--regular);
}

.Link,
.LinkHover {
    color: var(--sail-color-text-link);
    font-weight: var(--sail-font-weight-link);
    outline: 0;
    text-decoration: none;
}

.LinkHover:active,
.LinkHover:hover {
    color: var(--sail-color-text-link-hover);
}

.Form-Size-xs-Small {
    font-size: var(--sail-font-size-12);
}

.Form-Size-Small {
    font-size: var(--sail-font-size-14);
}

.Card {
    /* --card-border-color: var(--gray60); */
    --card-border-weight: solid;
    --card-border-width: 1px;
    --card--border-radius: var(--sail-card-radius);

    border: var(--card-border-color) var(--card-border-weight)
        var(--card-border-width);
    border-radius: var(--card--border-radius);
}

.shadow-onHover-medium:hover {
    box-shadow: var(--sail-shadow-medium);
}

.Card-body {
    padding: var(--padding);
}

.Card-footer {
    padding: var(--padding--x-small);
    /* color: var(--    ); */
    background-color: var(--blue50);
    border-top: var(--card-border-width) var(--card-border-weight)
        var(--card-border-color);
    border-radius: 0 0 var(--card--border-radius) var(--card--border-radius);
}

.padding-0 {
    padding: 0;
}
.padding-30 {
    padding: 30px;
}
.padding-bottom-0 {
    padding-bottom: 0;
}

.padding-top-20 {
    padding-top: var(--padding);
}

.padding-bottom-20 {
    padding-bottom: var(--padding);
}

.padding-bottom-50 {
    padding-bottom: var(--padding--xx-large);
}

.padding-top-30 {
    padding-top: var(--padding--x-large);
}
.padding-hr-10 {
    padding-left: 10px;
    padding-right: 10px;
}
.padding-hr-50 {
    padding-left: 50px;
    padding-right: 50px;
}
.padding-vr-10 {
    padding-top: 10px;
    padding-bottom: 10px;
}

.margin-0 {
    margin: 0;
}

.margin-30 {
    margin: var(--margin--x-large);
}

.margin-top-50 {
    margin-top: var(--margin--xx-large);
}

.margin-left-0 {
    margin-left: 0;
}

.margin-top-0 {
    margin-top: 0;
}

.margin-top-10 {
    margin-top: var(--margin--x-small);
}

.margin-top-30 {
    margin-top: var(--margin--x-large) !important;
}

.margin-top-20 {
    margin-top: var(--margin);
}

.margin-left-5 {
    margin-left: var(--margin--xx-small);
}

.margin-left-10 {
    margin-left: var(--margin--x-small);
}

.margin-left-20 {
    margin-left: var(--margin);
}

.margin-left-30 {
    margin-left: var(--margin--x-large);
}

.margin-bottom-5 {
    margin-bottom: var(--margin--xx-small);
}

.margin-right-20 {
    margin-right: var(--margin);
}
.margin-right-30 {
    margin-right: var(--margin--x-large);
}

.margin-bottom-10 {
    margin-bottom: var(--margin--x-small);
}

.margin-bottom-15 {
    margin-bottom: var(--margin--small);
}

.margin-bottom-20 {
    margin-bottom: var(--margin);
}
.margin-bottom-30 {
    margin-bottom: var(--margin--x-large);
}

.margin-bottom-50 {
    margin-bottom: var(--margin--xx-large);
}

.padding-20 {
    padding: var(--padding);
}

.design-Image {
    border: 0;
    display: inline-block;
    max-width: 100%;
    vertical-align: middle;
    border-radius: var(--card--border-radius) var(--card--border-radius) 0 0;
}

.roundedImg {
    border-radius: var(--border-radius--rounded);
}

.d-flex {
    display: flex;
}
.flex-col {
    flex-direction: column;
}
.space-between {
    justify-content: space-between;
}

.content-center {
    justify-content: center;
}

.content-right {
    justify-content: right;
}

.items-center {
    align-items: center;
}

.Form-group {
    display: block;
    margin-bottom: var(--margin);
}

.Form-label {
    display: block;
    margin-bottom: var(--margin--xx-small);
}

.btn {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 16px;
    border-radius: var(--sail-radius);
    --button-border-color: var(--gray60);
    --button-border-weight: solid;
    --button-border-width: 1px;
    border: var(--button-border-color) var(--button-border-weight)
        var(--button-border-width);
    color: var(--sail-color-text);
    background-color: var(--sail-color-white);
}

.btn:hover,
.btn:active {
    border: none;
    color: var(--sail-color-white);
    background-color: var(--sail-color-blue-500);
    border: var(--sail-color-blue-500) var(--button-border-weight)
        var(--button-border-width);
}

.btn-primary {
    color: var(--sail-color-white);
    background-color: var(--sail-color-blue-500);
}

.btn-primary:hover {
    background-color: var(--blue50);
    color: var(--blue500);
    border-color: var(--blue500);
}

.btn-secondary {
    color: var(--white);
    background-color: var(--pink500);
}

.btn-secondary:hover {
    background-color: var(--pink50);
    color: var(--pink500);
    border-color: var(--pink500);
}

.btn-small {
    font-size: var(--font-size--small);
    font-weight: var(--font-weight--semi-bold);
}

.btn-medium {
    padding: 10px 20px;
    font-size: var(--font-size--medium);
    font-weight: var(--font-weight--semi-bold);
}

.btn-large {
    padding: 10px 20px;
    font-size: var(--font-size--large);
    font-weight: var(--font-weight--semi-bold);
}

.dropdown {
    position: relative;
    display: inline-block;
    text-align: left;
    width: 85px;
}

.dropdown-text {
    cursor: pointer;
    position: absolute;
    text-indent: 10px;
    line-height: 32px;
    background-color: var(--gray30);
    border: 1px solid var(--gray50);
    border-radius: var(--sail-radius);
    box-shadow:
        0 1px 0 rgba(var(--sail-color-gray-30-rgb), 0.9) inset,
        0 1px 3px rgba(0, 0, 0, 0.1);
    width: 100%;
}

.dropdown-text:after {
    position: absolute;
    right: 6px;
    top: 15px;
    content: '';
    width: 0px;
    height: 0px;
    border-style: solid;
    border-width: 5px 4px 0 4px;
    border-color: var(--gray500) transparent transparent transparent;
}

.dropdown-text,
.dropdown-content Link {
    color: var(--sail-color-text);
    text-shadow: 0 1px #fff;
}

.dropdown-toggle {
    font-size: 0;
    z-index: 1;
    cursor: pointer;
    position: absolute;
    top: 0;
    border: none;
    padding: 0;
    margin: 0 0 0 1px;
    background: transparent;
    text-indent: -10px;
    height: 34px;
    width: 100%;
}

.dropdown-toggle:focus {
    outline: 0;
}

.dropdown-content {
    -webkit-transition: all 0.25s ease;
    -moz-transition: all 0.25s ease;
    -ms-transition: all 0.25s ease;
    -o-transition: all 0.25s ease;
    transition: all 0.25s ease;
    list-style-type: none;
    position: absolute;
    top: 32px;
    padding: 0;
    margin: 0;
    opacity: 0;
    visibility: hidden;
    border-radius: 3px;
    text-indent: 10px;
    line-height: 32px;
    background-color: var(--sail-color-white);
    border-radius: var(--sail-radius);
    border: 1px solid #ccc;
    width: 140px;
}

.dropdown-content a {
    display: block;
}

.dropdown-content a:hover {
    background: var(--gray30);
}

.dropdown-toggle:hover ~ .dropdown-text,
.dropdown-toggle:focus ~ .dropdown-text {
    background-color: var(--gray30);
}

.dropdown-toggle:focus ~ .dropdown-text {
    box-shadow:
        0 1px 3px rgba(var(--sail-color-black-rgb), 0.2) inset,
        0 1px 0 rgba(var(--sail-color-white-rgb), 0.8);
    z-index: 2;
}

.dropdown-toggle:focus ~ .dropdown-text:after {
    border-width: 0 4px 5px 4px;
    border-color: transparent transparent var(--gray500) transparent;
}

.dropdown-content:hover,
.dropdown-toggle:focus ~ .dropdown-content {
    opacity: 1;
    visibility: visible;
    top: 42px;
}

input[type='search'] {
    border: none;
    margin: 0;
    padding: 7px 8px;
    font-size: 14px;
    color: inherit;
    border: 1px solid var(--gray70);
    border-radius: var(--sail-radius);
}

input[type='search']::placeholder {
    color: #bbb;
}

input[type='search']:focus {
    box-shadow: 0 0 3px 0 #1183d6;
    border-color: #1183d6;
    outline: none;
}

input.nosubmit {
    border: 1px solid var(--gray70);
    width: 100%;
    padding: 9px 4px 9px 40px;
    background: transparent
        url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' class='bi bi-search' viewBox='0 0 16 16'%3E%3Cpath d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'%3E%3C/path%3E%3C/svg%3E")
        no-repeat 13px center;
}

.desk-container {
    padding-left: 50px;
    padding-right: 50px;
    padding-top: 70px;
    background-color: var(--gray10);
}

section {
    padding-top: var(--padding--xx-large);
    padding-bottom: var(--padding--xx-large);
}

.Row {
    display: flex;
    /* grid-gap: 10px; */
}

.Column-3 {
    width: 25%;
    margin: 0 var(--margin) 0 0;
}
.Column-3 .filter-heading {
    display: block;
    margin-bottom: var(--margin--x-small);
}

.Column-4 {
    width: 33.3%;
    margin: 0 var(--margin) 0 0;
}

.Column-8 {
    width: 66.6%;
}

.Column-9 {
    width: 75%;
}

.Column-6 {
    width: 50%;
    margin: 0 var(--margin) 0 0;
}

.grid-overall-container {
    width: 100%;
    columns: 2 !important;
}

@media screen and (max-width: 960px) {
    .grid-overall-container {
        columns: 1 !important;
    }
}

.grid-container-designs {
    display: grid;
}

.photo {
    padding: 0 0 var(--padding) 0;
    break-inside: avoid;
}

.small-iframe {
    background-color: var(--white);
    border: none;
    padding: 3px;
    border-radius: var(--sail-radius);
    box-shadow:
        0 1px 3px rgba(var(--sail-color-black-rgb), 0.2) inset,
        0 1px 0 rgba(var(--sail-color-white-rgb), 0.8);
}

.tag {
    padding: var(--margin--xx-small) var(--margin--x-small);
    border-radius: var(--sail-radius);
    --button-border-color: var(--gray60);
    --button-border-weight: solid;
    --button-border-width: 1px;
    border: var(--button-border-color) var(--button-border-weight)
        var(--button-border-width);
    color: var(--sail-color-text);
    font-size: var(--sail-font-size-16);
}

.tag:hover {
    border: var(--button-border-color) var(--button-border-weight)
        var(--button-border-width);
    color: var(--sail-color-text);
    background-color: var(--sail-color-white);
    box-shadow: var(--sail-shadow-small);
}

.gap-10 {
    gap: var(--margin);
}
.gap-20 {
    gap: var(--margin--x-small);
}
.gap-5 {
    gap: var(--margin--xx-small);
}

.ul-list-tabs {
    display: flex;
    gap: var(--margin);
    font-size: var(--sail-font-size-18);
}

.list-tabs {
    list-style-type: none;
}

.list-tabs a {
    cursor: pointer;
    color: var(--gray300);
    font-weight: var(--font-weight--semi-bold);
}

.Tab.is-active a,
.Tab a:hover {
    color: var(--gray800);
}

.TabPanel {
    display: none;
}
.TabPanel.is-active {
    display: block;
}

hr {
    margin: var(--margin) 0;
    border: 1px solid var(--gray40);
}

/* The sticky */
.sticky-top {
    position: -webkit-sticky;
    position: sticky;
    top: var(--margin);
}

.InputSectionDetails h6 {
    font-weight: var(--font-weight--semi-bold);
    font-size: var(--sail-font-size-18);
}

.InputSectionDetails p {
    font-weight: var(--font-weight--semi-bold);
    font-size: var(--sail-font-size-14);
}

.InputSectionDetails p {
    font-weight: var(--font-weight--regular);
}

.Form-control {
    font-weight: var(--font-weight--regular);
}

.Form-input label {
    color: var(--gray100);
}

.Form-input input {
    width: 98%;
    padding: var(--padding--x-small);
    border: 1px solid var(--gray50);
    border-radius: var(--border-radius--input);
    font-size: var(--font-size--medium);
    color: var(--gray900);
}

/* Drop Zoon */
.upload-area__drop-zoon {
    position: relative;
    height: 11.25rem; /* 180px */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border: 2px solid var(--gray40);
    border-radius: var(--border-radius);
    margin-top: var(--margin--x-small);
    background-color: var(--gray30);
    cursor: pointer;
    transition: border-color 300ms ease-in-out;
    box-shadow: inset 0 2px 2px rgba(0, 0, 0, 0.1);
}

.upload-area__drop-zoon p {
    font-size: var(--font-size--medium);
    font-weight: var(--sail-font-weight-regular);
}

.upload-area__drop-zoon svg {
    font-weight: var(--sail-font-weight-regular);
}

.Form-Code-Editor section {
    padding-top: 0;
    padding-bottom: 0;
    margin: var(--margin--x-small) 0;
}

/* IMAGE LIST ITEM */
.list-item-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #c2c7d0;
    padding: var(--padding--x-small) var(--padding--small);
    border-radius: 10px;
    margin-bottom: var(--margin--xx-small);
    margin-top: var(--margin--xx-small);
}
.list-item-box-left,
.list-item-box-right,
.list-icon-img {
    display: flex;
    align-items: center;
}
.list-icon-img {
    margin-right: var(--margin--xx-small);
}
.list-item-btn {
    margin-left: var(--margin--x-small);
    cursor: pointer;
}
.list-item-view-btn {
    padding: var(--padding--xx-small) 0px;
}
.list-item-icon-btn {
    position: relative;
    top: 4px;
}

/* FULL PAGE IFRAME CSS START  */
.top-0 {
    top: 0 !important;
}

.w-full {
    width: 100%;
}

.min-h-screen {
    min-height: 100vh;
}

.max-h-screen {
    max-height: 100vh;
}

.fixed {
    position: fixed;
}

.left-0 {
    left: 0;
}

.relative {
    position: relative;
}

.p-4 {
    padding: 1.5rem !important;
}

.w-full {
    width: 100%;
}

.z-50 {
    z-index: 50;
}

.card {
    --bs-card-spacer-y: 1rem;
    --bs-card-spacer-x: 1rem;
    --bs-card-title-spacer-y: 0.5rem;
    --bs-card-border-width: 1px;
    --bs-card-border-color: rgba(0, 0, 0, 0.175);
    --bs-card-border-radius: 0.375rem;
    --bs-card-box-shadow: ;
    --bs-card-inner-border-radius: calc(0.375rem - 1px);
    --bs-card-cap-padding-y: 0.5rem;
    --bs-card-cap-padding-x: 1rem;
    --bs-card-cap-bg: rgba(0, 0, 0, 0.03);
    --bs-card-cap-color: ;
    --bs-card-height: ;
    --bs-card-color: ;
    --bs-card-bg: #fff;
    --bs-card-img-overlay-padding: 1rem;
    --bs-card-group-margin: 0.75rem;
    word-wrap: break-word;
    background-clip: initial;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.175);
    border-radius: 0.375rem;
    display: flex;
    flex-direction: column;
    height: var(--bs-card-height);
    min-width: 0;
    position: relative;
}

.card-body {
    color: var(--bs-card-color);
    flex: 1 1 auto;
    padding: var(--bs-card-spacer-y) var(--bs-card-spacer-x);
}

.p-3 {
    padding: 1rem !important;
}

.pt-2 {
    padding-top: 0.5rem !important;
}

.pb-2 {
    padding-bottom: 0.5rem !important;
}

.justify-between {
    justify-content: space-between;
}

.items-center {
    align-items: center;
}

.flex {
    display: flex;
}

.items-center {
    align-items: center;
}

.active {
    border: 1px solid #316be8;
}

.viewport-button {
    --tw-text-opacity: 1;
    border-radius: 10px;
    color: rgba(125, 81, 203, var(--tw-text-opacity));
    margin-left: 5px;
    padding: 0.25rem 10px;
}

button {
    text-transform: none;
}

button {
    overflow: visible;
}

.svg-size > div svg {
    height: auto;
    width: 30px;
}

.rounded-2xl {
    border-radius: 1rem;
}

.bg-gray-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 244, 245, var(--tw-bg-opacity));
}

.display-box,
.preview-box {
    max-height: calc(100vh - 100px);
    min-height: calc(100vh - 100px);
}

.mt-3 {
    margin-top: 1rem !important;
}

.overflow-hidden {
    overflow: hidden !important;
}

.preview-box {
    height: calc(100vh - 100px);
    max-height: calc(100vh - 100px);
    min-height: calc(100vh - 100px);
}

.mx-auto {
    margin-left: auto !important;
    margin-right: auto !important;
}

.rounded-lg {
    border-radius: 0.5rem;
}

.border-none {
    border: none;
}
/* FULL PAGE IFRAME CSS END  */

/* NAVBAR CSS START  */

a {
    text-decoration: none;
}
ul {
    list-style: none;
}
.header {
    position: relative;
    display: grid;
    grid-template-columns: 20% auto 30%;
    place-items: center;
    height: 100%;
}
.buildfast-navbar {
    width: 100%;
    padding: var(--padding--x-small) 0rem;
    z-index: 0;
    position: fixed;
    box-shadow: 4px 0px 10px 4px #eee;
    background-color: white;
}
.buildfast-navbar-container {
    padding-left: 50px;
    padding-right: 50px;
    margin: auto;
}
.buildfast-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.buildfast-header-logo {
    display: flex;
    align-items: center;
    cursor: pointer;
}
.buildfast-header-logo > img {
    height: auto;
    width: 120px;
}
.buildfast-header-logo > p {
    margin-left: 0.5rem;
    font-weight: 500;
    font-size: 1.1rem;
}
.buildfast-menu-main {
    display: flex;
    align-items: center;
}
.buildfast-menu-main > li {
    margin: 0rem 0.5rem;
}
.buildfast-menu-main > li > a {
    padding: 0.5rem 0.6rem;
    font-size: 0.9rem;
    color: black;
    border-radius: 5px;
}
.buildfast-menu-main > li > a:hover {
    background-color: #edfdfd;
    color: #0990ac;
}
.nav-down-arrow {
    margin-left: 0.5rem;
    position: relative;
    top: 1.5px;
    transition: 0.5s;
}
.menu-resources .nav-up-arrow {
    top: 0px;
    transform: rotate(180deg);
}
.buildfast-free-button {
    display: flex;
    align-items: center;
    border: 2px solid #00b4d8;
    padding: var(--padding--xx-small) var(--padding);
    border-radius: 10px;
    transition: all 0.5s ease-in-out;
}
.buildfast-free-button:hover {
    cursor: pointer;
    background-color: #00b4d8;
    color: white;
}
.buildfast-banner-section {
    padding: 2rem 4rem;
    display: flex;
    position: fixed;
    top: -100%;
    transition: 0.5s ease-in-out;
    z-index: -1;
}
.active {
    top: 72px;
    z-index: -1;
}
.buildfast-banner-left {
    width: 25%;
    margin-right: 1rem;
}
.buildfast-banner-heading > p:nth-child(1) {
    font-size: 1.8rem;
    font-weight: 600;
}
.buildfast-banner-heading > p:nth-child(2) {
    font-size: 0.85rem;
    color: grey;
    margin: 0.6rem 0rem;
}
.buildfast-banner-heading > a {
    font-size: 0.9rem;
    color: #00b4d8;
}
.buildfast-banner-heading > a > i {
    transform: rotate(-90deg);
}
.buildfast-banner-heading > a:hover {
    text-decoration: underline;
}
.buildfast-banner-right {
    width: 75%;
    margin-left: 2rem;
}
.buildfast-banner-all-links {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.buildfast-banner-link {
    display: flex;
    width: 30%;
    cursor: pointer;
}
.buildfast-banner-link:hover .buildfast-link-info > p:nth-child(1) {
    color: #00b4d8;
    cursor: pointer;
}
.buildfast-banner-all-links .second-row {
    margin-top: 2rem;
}
.buildfast-banner-link > p {
    font-size: 25px;
    margin-right: 1rem;
}
.buildfast-link-info > p:nth-child(1) {
    font-size: 1.2rem;
}
.buildfast-link-info > p:nth-child(2) {
    font-size: 0.8rem;
    font-weight: 300;
    color: grey;
    margin-top: 0.5rem;
}
.hamburger {
    display: none;
    cursor: pointer;
}
.bar {
    display: block;
    width: 25px;
    height: 3px;
    margin: 5px auto;
    transition: all 0.3s ease-in-out;
    background-color: black;
}
.grey-background {
    background-color: #f8f8fa;
}
/* Responsive CSS */
@media (min-width: 900px) and (max-width: 1200px) {
    html {
        font-size: 14px;
    }
}
@media (min-width: 600px) and (max-width: 900px) {
    .buildfast-row .item-right {
        display: flex;
        align-items: center;
    }
    .hamburger {
        display: block;
        margin-left: 2rem;
    }
    .hamburger.active .bar:nth-child(2) {
        opacity: 0;
    }
    .hamburger.active .bar:nth-child(1) {
        transform: translateY(8px) rotate(45deg);
    }
    .hamburger.active .bar:nth-child(3) {
        transform: translateY(-8px) rotate(-45deg);
    }
    .buildfast-row .item-center {
        position: fixed;
        left: 100%;
        top: 70px;
        gap: 0;
        background-color: white;
        width: 100%;
        margin: auto;
        text-align: center;
        transition: 0.3s;
        padding: 1rem 0rem;
    }
    .buildfast-menu-main {
        margin: 10px 0px;
        justify-content: center;
    }
    .buildfast-row .active {
        left: 0;
    }
    .active {
        top: 112px;
        z-index: -1;
    }
    .buildfast-banner-section {
        flex-direction: column;
        justify-content: center;
    }
    .buildfast-banner-left {
        width: 100%;
    }
    .buildfast-banner-right {
        width: 100%;
        margin-left: 0px;
        margin-top: 2rem;
    }
}
@media (min-width: 300px) and (max-width: 600px) {
    html {
        font-size: 14px;
    }
    .buildfast-row .item-right {
        display: flex;
        align-items: center;
    }
    .buildfast-free-button {
        display: none;
    }
    .hamburger {
        display: block;
        margin-left: 2rem;
    }
    .hamburger.active .bar:nth-child(2) {
        opacity: 0;
    }
    .hamburger.active .bar:nth-child(1) {
        transform: translateY(8px) rotate(45deg);
    }
    .hamburger.active .bar:nth-child(3) {
        transform: translateY(-8px) rotate(-45deg);
    }
    .buildfast-row .item-center {
        position: fixed;
        left: -100%;
        top: 70px;
        gap: 0;
        background-color: white;
        width: 30%;
        margin: auto;
        text-align: none;
        transition: 0.3s;
    }
    .buildfast-menu-main {
        margin: 10px 0px;
        flex-direction: column;
        align-items: flex-start;
    }
    .buildfast-menu-main li {
        margin-top: 1.2rem;
    }
    .buildfast-row .active {
        left: 0;
    }
    .buildfast-banner-section {
        top: -200%;
        position: absolute;
    }
    .active {
        top: 62px;
        z-index: -1;
    }
    .buildfast-banner-section {
        flex-direction: column;
        align-items: flex-end;
    }
    .buildfast-banner-left {
        width: 70%;
    }
    .buildfast-banner-right {
        width: 70%;
        margin-left: 0px;
        margin-top: 2rem;
    }
    .buildfast-banner-all-links {
        flex-direction: column;
        justify-content: flex-end;
    }
    .buildfast-banner-link {
        width: 100%;
        margin-top: 1rem;
    }
}

/* NAVBAR CSS END  */

/* DESGIN INSPIRATION PAGE CSS START */

.grid-container {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    padding: 10px;
    max-width: 83%;
    margin: 0px auto;
}

.grid-item {
    padding: 20px;
    text-align: center;
    margin: auto;
}

.design-inspiration-layout {
    width: 300px;
    height: 400px;
    transition: transform 0.2s;
    box-shadow: 0px 3px 6px #00000029;
}

.design-inspiration-layout:hover {
    -ms-transform: scale(1.5); /* IE 9 */
    -webkit-transform: scale(1.5); /* Safari 3-8 */
    transform: scale(1.1);
}

@media screen and (max-width: 960px) {
    .grid-container {
        grid-template-columns: repeat(2, 1fr);
    }
    .tw-grid-responsive {
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }

    .grid-item {
        margin: auto;
    }

    .grid-container-designs {
        grid-template-columns: auto !important;
    }

    .photo {
        height: 100% + 20%;
    }
    .card-footer {
        display: block !important;
        justify-content: center !important;
    }
}

/* DESGIN INSPIRATION PAGE CSS END */

/* SPECIFIC DESGIN INSPIRATION PAGE CSS START */
.p-0 {
    padding: 0;
}
.product-details {
    width: 60%;
    margin: auto;
}

.product-details h6 {
    font-size: 1.5rem;
}

.product-details p {
    font-size: 1rem;
}

.visit-product-site-button {
    text-align: right;
}

.advertise-web-component-product {
    background-color: #fcf3e7;
    margin: var(--margin) 0;
    border: none;
}

.advertise-web-component-product h6 {
    font-size: 1.2rem;
}

.display-flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.tansformed-img {
    border-radius: 0.375rem;
}
.tranformed-img-mobile {
    height: 400px;
    width: 100%;
    object-fit: cover;
    object-position: top center;
}
/* SPECIFIC DESGIN INSPIRATION PAGE CSS END */

/* BLOG PAGE CSS START */

.buildfast-section {
    padding-top: 50px;
}

.buildfast-heading {
    text-align: center;
}

.buildfast-margin-top-4 {
    margin-top: 50px;
}

.buildfast-margin-20 {
    margin: 20px;
}

.buildfast-row {
    display: flex;
}

.blog-grid-container {
    display: grid;
    grid-template-columns: auto auto auto auto;
}

.buildfast-col-6 {
    width: 50%;
}

.buildfast-blog-image {
    max-width: 400px;
    height: auto;
    border-radius: 10px;
    transition: 1s;
    margin: auto;
}

.buildfast-blog-image:hover {
    transform: scale(1.05);
}

.buildfast-blog-image > img {
    object-fit: cover;
    border-radius: 10px;
    width: 100%;
    height: 100%;
}

.buildfast-margin-b-10 {
    margin-bottom: 10px;
}

.buildfast-blogs {
    width: 80%;
    margin: auto;
}

.buildfast-blog-posted-date {
    color: #52525b;
    font-size: 0.75rem;
    font-weight: 300;
}

.buildfast-blog-tiltle h4 {
    font-size: 1.2rem;
    font-weight: 600;
    margin: 0;
}

.buildfast-a-tag {
    text-decoration: none;
    color: black;
}

.buildfast-a-tag:hover {
    color: #0d6efd;
}

.aspect-ratio {
    aspect-ratio: 16/9;
}
@media (max-width: 800px) {
    .buildfast-container {
        width: 90%;
        margin-left: 10px;
        margin-right: 10px;
        margin: auto;
    }

    .buildfast-blogs {
        width: 100%;
    }

    .buildfast-row {
        display: block;
    }

    .buildfast-col-6 {
        width: 100% !important;
        margin: auto;
        margin-bottom: 40px;
    }
    .nextjs-components {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }
}

/* BLOG PAGE CSS END */

/* BLOG PAGE DETAIL CSS START */

.buildfast-blog-detail-image img {
    width: 100%;
    height: auto;
}

/* BLOG PAGE DETAIL CSS END */

/* MOBILE VIEW CSS START  */

/* DESIGN PAGE CSS START  */

@media (min-width: 300px) and (max-width: 600px) {
    .desk-container {
        padding-left: var(--padding--x-small) !important;
        padding-right: var(--padding--x-small) !important;
    }

    .Row {
        display: block;
    }

    .Column-3,
    .Column-9,
    .Column-6,
    .Column-4,
    .Column-8,
    .Column-9 {
        width: 100% !important;
    }

    .grid-item {
        padding-left: 0;
        padding-right: 0;
        padding-top: var(--padding);
    }

    .btn-with-icon-hidetext span {
        display: none;
    }
}

@media (max-width: 850px) {
    .hide-below-desktop {
        display: none;
    }
}
/* DESIGN PAGE CSS END  */
@media (min-width: 1024px) {
    .hide-above-desktop {
        display: none;
    }
}
/* BLOG PAGE CSS START  */

@media (min-width: 300px) and (max-width: 600px) {
    .blog-grid-container {
        display: grid;
        grid-template-columns: auto !important;
    }
}

/* BLOG PAGE CSS END  */

/* DESIGN INSPIRATION CSS START */

@media (min-width: 300px) and (max-width: 600px) {
    .product-details {
        width: 100% !important;
    }
    .mb-block {
        display: block;
    }
}

/* DESIGN INSPIRATION CSS END */
/* MOBILE VIEW CSS END  */

.welcome-Aboard-section {
    padding-top: 100px;
    width: 360px;
    margin: auto;
}

.google-login-button iframe {
    margin: auto !important;
}

/* PROFILE PAGE CSS START  */

.profile-img img {
    border-radius: 50%;
    width: 50px;
    height: 50px;
}

/* PROIFLE PAGE CSS END  */

/* DESIGN CARD FREE INREVIEW CSS START  */

.card-body-img-container {
    position: relative;
}

.onImage-tag {
    position: absolute;
    top: 8px;
    right: 16px;
}

.text-white {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
}

.uppercase {
    text-transform: uppercase;
}

.bg-green-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(16, 185, 129, var(--tw-bg-opacity));
}

.rounded-md {
    border-radius: 0.375rem;
}

.text-xs {
    font-size: 0.75rem;
    line-height: 1rem;
}

.font-bold {
    font-weight: 700;
}

.p-1 {
    padding: 0.25rem;
}
.padding-bottom-30 {
    padding-bottom: var(--padding--x-large);
}
.px-2 {
    padding-left: 0.625rem;
    padding-right: 0.625rem;
}
.px-4 {
    padding-left: 24px;
    padding-right: 24px;
}
/* DESIGN CARD FREE INREVIEW CSS END  */

/* Next JS Component Start */

.nextjs-Container {
    position: relative;
    display: grid;
    grid-template-columns: 25% auto;
    gap: 30px;
    height: 100vh;
    overflow: hidden;
}

.nextjs-navContainer {
    overflow-y: scroll;
    padding: 20px;
    position: sticky;
    top: 80px;
}
.nextjsnav {
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.nextjs-designsContainer {
    height: 100vh;
    overflow-y: scroll;
}
.nextjs-designs {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 30px;
    padding: 20px;
}
.hamburger-icon {
    display: none;
}
@media only screen and (max-width: 1200px) {
    .tw-grid-responsive {
        grid-template-columns: repeat(3, 1fr);
    }
}
/* Tablet */
@media only screen and (max-width: 900px) {
    .header {
        grid-template-columns: auto auto;
    }
    .nextjs-components {
        grid-template-columns: repeat(2, 1fr);
        gap: 40px;
    }
    .nextjs-designs {
        display: grid;
        grid-template-columns: auto;
        gap: 30px;
        padding: 20px;
    }
    .tw-grid-responsive {
        grid-template-columns: repeat(2, 1fr);
    }
    .hamburger-icon {
        display: flex;
        align-items: center;
        justify-content: end;
        width: 100%;
    }
}

/* Mobile */
@media screen and (max-width: 580px) {
    .hamburger-icon {
        display: flex;
        align-items: center;
        justify-content: end;
        width: 100%;
    }
    .header {
        grid-template-columns: auto auto;
    }
    .nextjs-components {
        grid-template-columns: repeat(1, 1fr);
        gap: 40px;
    }
    .tw-grid-responsive {
        grid-template-columns: repeat(1, 1fr);
    }
    .nextjs-Container {
        display: grid;
        grid-template-columns: auto;
    }

    .nextjs-navContainer {
        display: none;
    }
    .nextjsnav {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }
    .nextjs-designsContainer {
        height: 100vh;
        overflow-y: scroll;
    }
    .nextjs-designs {
        display: grid;
        grid-template-columns: auto;
        gap: 30px;
        padding: 20px;
    }
}
.nextjs-design {
    border-radius: 10px;
    background: white;
    overflow: hidden;
}
.nextjs-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
    background-color: var(--blue50);
}

.nextjs-footer-title {
    font-size: 16px;
    color: black;
    margin: 0px;
}
.sidebar-item {
    color: var(--gray200);
    transition: all 200ms ease-in;
    cursor: pointer;
}
.sidebar-item:hover {
    padding-left: 6px;
    color: var(--sail-color-pink-500);
}

.max-width {
    max-width: 1200px;
    margin: 0 auto;
}
.border-right-5 {
    border-right: 4px solid var(--gray100);
}

.steps-container {
    border-left: 0.1px solid #1a1a1a;
    display: flex;
    flex-direction: column;
    gap: 30px;
}
.step-title {
    position: relative;
    margin-left: 0px;
    margin-bottom: 16px;
    margin-top: 0px;
}
.step-desc {
    padding: 0px 35px;
    padding-bottom: 6px;
    font-size: 14px;
    margin: 0px;
}
.step-title > span {
    position: absolute;
    height: 26px;
    width: 6px;
    top: 0;
    left: 0;
    border-radius: 0px 4px 4px 0px;
    background-color: #1a1a1a;
}
.previewUi {
    background: url('https://s3-alpha-sig.figma.com/img/3094/92ab/c396276fb1af5134781d999c73f8e0c3?Expires=1710115200&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=CG6aC6yycM76iiLyYOwWB6USHKQw02oBTyfnjyu3dwNFYCBPFr3FfaAxpTwrF7bvd3g~1NIQYAcTZVKHHyYJX7pyy-ltbfh8Opy-Z1gXpuGDP1g4z~U0oYKWQNU5H3lj~N1SvhLHbjKymFpsCYSVaGlOWHg0gPuGawjtgkAn77Ogyv7hkB8lxmYDKdyDPz9rzfdUZzp8sh6PhQIq5hFu2tJheSq7K9FkVg-CuQmnvRJD5FBKX8AkHknYJ-uvDUQVfmxAO1rLKudqe1JTxFouaKdVZIhUOlealHQpQSCGnXtr8Tm34bz0H8oXX3kqasS4sA2buO24N4uoUwWtwEusTA__');
    background-position: center;
    background-size: contain;
    background-repeat: repeat-x;
    height: 600px;
    border-radius: 12px;
    border: 0.1px solid var(--gray100);
}

.fixedDesktopIframe {
    border: none;
    width: 1920px;
    height: 1080px;
}

.copyButton {
    height: 25px;
    position: absolute;
    top: 15px;
    bottom: 0;
    right: 15px;
    cursor: pointer;
    padding: 3px;
    border-radius: 6px;
    transition: all 200ms ease-in;
}
.copyButton-icon {
    height: 18px;
    width: 18px;
    transition: all 200ms ease-in-out;
    border-radius: 6px;
}
/* Next JS Component End */
.templates {
    display: grid;
}
.templates-card {
    width: 100%;
    padding: 20px;
    cursor: pointer;
    border-radius: 25px;
}
.templates-card > img {
    height: 500px;
    width: 100%;
    overflow: hidden;
    transition: transform 0.2s;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 15px;
    object-fit: cover;
    object-position: top center;
}
.templates-card-text {
    text-align: center;
    padding: 10px;
}
.templates-card-text h4 {
    padding: 0px;
    margin: 0px;
    font-weight: 500;
    font-size: 18px;
    color: var(--sail-color-blue-500-rgb);
}
.templates-card-text p {
    padding: 0px;
    margin: 0px;
    font-weight: 400;
    font-size: 12px;
    color: #000;
}
.specific-templates {
    padding: 120px 0px;
}
.template-design-text {
    padding: 20px 0px;
}
.template-design-text h2 {
    padding: 10px 0px;
    margin: 0;
    color: var(--sail-color-pink-300);
}
.template-design-text p {
    padding: 10px 0px;
    margin: 0;
    font-size: 18px;
}
.specific-templates-container {
    display: flex;
    max-width: 90%;
    margin: 0 auto;
    padding: 30px;
}
.template-design {
    width: 70%;
    overflow: hidden;
}
.template-design-image {
    overflow: hidden;
    border-radius: 30px;
}

.templates-design-sidebar {
    padding: 30px;
    position: relative;
    width: 30%;
}
.template-pricing-card {
    border-radius: 4px;
    border: 1px solid #e0e0e1;
    position: fixed;
    padding: 100px 30px;
    width: 450px;
}
.template-pricing-card h3 {
    padding: 0;
    margin: 20px 0px;
    font-size: 20px;
    font-weight: 600;
    line-height: normal;
}
.template-pricing-features {
    margin: 20px 0px;
}
.template-pricing-features p {
    margin: 0;
    padding: 4px 0px;
    display: flex;
    align-items: center;
    gap: 10px;
}
.template-pricing-tag {
    background-color: var(--sail-color-blue-500);
    color: white;
    padding: 2px 6px;
    border-radius: 4px;
    font-size: 12px;
}
.template-btn {
    padding: 10px 60px;
    width: 100%;
    background-color: var(--sail-color-pink-300);
    color: white;
    border: 1px solid var(--sail-color-pink-300);
    border-radius: 6px;
}
.template-btn:hover {
    background-color: white;
    color: var(--sail-color-pink-300);
}
@media (max-width: 480px) {
    .specific-templates-container {
        flex-direction: column;
        gap: 20px;
        padding: 0;
    }
    .template-design {
        width: 100%;
        overflow: hidden;
    }
    .templates-design-sidebar {
        width: 100%;
        padding: 0;
    }
    .template-pricing-card {
        position: relative;
        width: 100%;
    }
}
/* pricing page start */
.border {
    border: 1px solid var(--sail-color-text);
}
/* pricing page end */
/* pricing page */
:root {
    /* Code Block styles */
    --color-code-bg: #252935;
    --color-code-main: #e1ebfe;
    --color-code-keyword: #ff6675;
    --color-code-class: #bf9dff;
    --color-code-variable: #69c6ff;
    --color-code-string: #81bcff;
    --color-code-params: #ffa259;
    --color-code-tag: #74e59d;
    --color-code-number: #ff6262;
    --color-code-comment: #6c7f93;
}

.diff {
    display: inline-block;
    width: 100%;
}
.diff--added {
    color: #70b979;
    background-color: #25f84d 21;
}
.diff--added::before {
    content: '+';
    opacity: 0.8;
}
.diff--removed {
    color: #f1acaf;
    background-color: #950000 69;
}
.diff--removed::before {
    content: '-';
    opacity: 0.8;
}
