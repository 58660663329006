.cc-container{
    margin-top: 76px;
}
.cc-converter-heading{
    width: 90%;
    margin: auto;
    text-align: center;
    font-size: 1.3rem;
    font-weight: 500;
    padding: 1rem 0rem;
    border-radius: .4rem;
    background-color: aliceblue;
}
.cc-editor{
    width: 90%;
    margin: auto;
    margin-top: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.cc-io-editor{
    width: 49%;
    border: 1px solid #ddd;
    border-radius: .5rem;
}
.cc-io-output{
    height: 648px;
    overflow: scroll;
}
.cc-io-output-label{
    padding: .5rem 1rem;
    font-weight: 600;
    font-size: 1.1rem;
}
.cc-btn{
    margin-top: 1rem;
    text-align: center;
}
.cc-convert-btn{
    background-color: #306be8;
    padding: .5rem 1.5rem;
    color: white;
    border-radius: 26px;
    font-size: 1.1rem;
    font-weight: 400;
}
.cc-convert-btn:hover{
    box-shadow: 0 0 1px 0 rgba(0, 0, 0, .04), 0 0 6px 2px rgba(49, 107, 232, .12), 0 0 20px 10px rgba(49, 107, 232, .18);
}
.cc-des{
    background-color: #eff3fd;
    margin-top: 2rem;
}
.cc-des-wrapper{
    width: 90%;
    margin: auto;
    padding: 2rem;
}
.cc-des-heading{
    text-align: center;
    font-size: 1.6rem;
    font-weight: 500;
    color: black;
    font-family: Biofolio Ultimate, sans-serif;
}
.cc-des-text{
    margin-top: 2rem;
    /* display: flex; */
    justify-content: space-evenly;
    color: black;
}
.cc-des-text > div{
    width: 70%;
    margin: auto;
}
.cc-more-links{
    background-color: white;
}
.cc-more-links-wrapper{
    width: 90%;
    margin: auto;
    padding: 2rem 0rem;

}
.cc-more-links-heading{
    text-align: center;
    font-size: 1.5rem;
    color: black;
    font-family: Biofolio Ultimate, sans-serif;
}
.cc-links-box{
    margin-top: 2rem;
    display: flex;
    justify-content: space-evenly;
}
.cc-links{
    display: flex;
    flex-direction: column;
    width: fit-content;
}
.cc-links > a{
    color: black;
    padding: .2rem;
}
.cc-tailwind{
    margin: 0.5rem 1rem;
}
.cc-class{
    font-weight: 400;
    font-style: italic;
    color: #838383;
}
.cc-style{
    padding: .5rem;
    background-color: #303030;
    color: #eff3fd;
    border-radius: .4rem;
    margin-top: .3rem;
    font-weight: 400;
}