@tailwind base;
@tailwind components;
@tailwind utilities;


.webkit-scroll::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    display: none;
}

.webkit-scroll::-webkit-scrollbar-track {
    background: white;
}

.webkit-scroll::-webkit-scrollbar-thumb {
    background: #eaf0fd;
    border-radius: 6px;
}