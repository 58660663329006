@import url('../../style.css');
/* canvas */
.gjs-cv-canvas{
    width: 100% !important;
    height: 100% !important;
}
.gjs-one-bg{
    background-color: white !important;
}


.db-nav {
    padding: 0.5rem 0.5rem 0.5rem;
    background-color: rgb(var(--sail-color-blue-50-rgb));
}
.db-nav-btn {
    width: 92%;
    margin: auto;
    display: flex;
    justify-content: flex-end;
}
.db-nav-btn-export {
    width: fit-content;
    background-color: white;
    padding: 0.3rem 1rem;
    border-radius: 0.4rem;
    cursor: pointer;
    transition: all 0.3s linear;
}
.db-nav-btn-export:hover {
    box-shadow: inset 0 0 5px 1px rgb(214, 214, 214);
}
.db-main-container {
    height: 100vh;
    padding-top: 5rem;
    overflow: hidden;
}
.db-editor {
    display: flex;
    height: 100%;
}
.db-editor-left {
    width: 30%;
    height: calc(100vh - 130px);
    background-color: rgb(var(--sail-color-gray-10-rgb));
}
.db-editor-right {
    height: 100%;
    width: 70%;
    background-color: rgb(var(--sail-color-gray-20-rgb));
    height: calc(100vh - 130px);
    padding: 2rem;
    overflow: hidden;
}
.db-editor-menu {
    padding: 1rem;
    height: 100%;
    overflow-y: scroll;
}
.db-editor-menu-heading {
    padding: 0.5rem 1rem;
}
.db-editor-page {
    background-color: white;
    height: 100%;
    overflow-y: scroll;
    box-shadow: 0 0 10px 5px rgb(228, 228, 228);
    border-radius: 4px;
}
.db-design-card-box {
    margin: 1rem 0rem;
}
.dbo-container-wrapper {
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.4);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 999;
}
.dbo-container {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.dbo-code-box {
    width: 60%;
    height: 600px;
    background-color: white;
    border-radius: 0.4rem;
}
.dbo-code-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.8rem 1rem;
    background-color: rgb(var(--sail-color-blue-50-rgb));
    border-radius: 0.4rem 0.4rem 0 0;
}
.dbo-code-cross {
    display: flex;
    align-items: center;
    justify-content: center;
}
.dbo-code-block {
    height: 100%;
    background-color: #1e1e1e;
    border-radius: 0 0 0.4rem 0.4rem;
    padding: 0rem 0rem;
    position: relative;
    overflow: scroll;
    color: rgba(255, 255, 255, 0.749);
}
.dbo-code-copy {
    position: absolute;
    right: 15px;
    top: 10px;
    cursor: pointer;
}
.dbf-frame-container {
    /* min-height: min-content; */
    height: fit-content;
    /* height: 400px; */

    position: relative;
}
.dbf-iframe-handler {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    background: transparent;
    cursor: move;
    z-index: 1;
}
.dbf-iframe {
    width: 100%;
    border: none;
    /* height: 100vh; */
    /* min-height: fit-content; */
    /* overflow: scroll; */
    overflow: hidden;
}
.dbf-iframe-handler:hover {
    border: 2px solid green;
}
